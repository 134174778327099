import React, { createContext, useState } from 'react';

import localeData from './localeData.json';

const LocalizationContext = createContext();

const LocalizationProvider = ({ children }) => {
    
    const [currentLocale, setCurrentLocale] = useState('ru'); // default locale

    const setLocale = (newLocale) => {
        setCurrentLocale(newLocale);
    }

    const t = (k) => {
        return localeData[k][currentLocale];
    }

    return (
        <LocalizationContext.Provider value={{
            currentLocale, // Текущий язык
            setLocale, // Установка языка
            t // Данные локализации
        }}
        >
            {children}
        </LocalizationContext.Provider>
    );

};

export { LocalizationProvider, LocalizationContext };